import React from 'react';
import classes from './WelcomeScreen.module.css';
import WelcomeMessage from './WelcomeMessage/WelcomeMessage';
import WelcomeScreenSuggestedActions from './WelcomeScreenSuggestedActions/WelcomeScreenSuggestedActions';
import { useLanguage } from "../../../UiKit/Wizard/langugeContext";
import { translations } from './translations';

function WelcomeScreen () {
    const { currentLanguage } = useLanguage();
    const welcomeScreenTranslations =  translations[currentLanguage]["welcomeScreen"];
    return (
        <div className={`${classes['welcome-screen']}`}>
            <div className={`${classes['welcome-screen__image-container']}`}>
                <WelcomeMessage />
            </div>
            <h2 className={`${classes['welcome-screen__heading']}`}>
                {welcomeScreenTranslations.heading}
            </h2>
            <p className={`${classes['welcome-screen__sub-heading']}`}>
                {welcomeScreenTranslations.subHeading}
            </p>
            <div className={`${classes['welcome-screen__actions-container']}`}>
                <WelcomeScreenSuggestedActions />
            </div>
        </div>
    )
}

export default WelcomeScreen;