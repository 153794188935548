import useChatbot from "./useChatbot";

const usePasswordResetActionHandler = () => {
    const { openPasswordResetWizard } = useChatbot();

    const handlePasswordResetAction = (action) => {
        if(_isUsernameDob(action)){
            openPasswordResetWizard({ name: "openWelcomePage", data: action.payload.activity });
            return;
        }
        if(_isCancel(action)){
            openPasswordResetWizard({ name: "cancel", data: action.payload.activity });
            return;
        }
        if(_isCancelConfirmed(action)){
            openPasswordResetWizard({ name: "cancelConfirmed", data: action.payload.activity });
            return;
        }
        if (!action.payload.activity.attachments) {
            if (_isExceededAttemptMessage(action)) {
                openPasswordResetWizard({
                    name: "openAuthenticationPage",
                    data: {
                        exceededAttemptMessage:
                            "Passwords can only be changed once per day. If you need to change your password, wait until tomorrow or call (800) 300-1506 for more help.",
                    },
                });
                return;
            }
            if (_isUserNotFound(action)) {
                openPasswordResetWizard({
                    name: "openAuthenticationPage",
                    data: { userNotFound: action.payload.activity.text },
                    flag: "userNotFound",
                });
                return;
            }
            if (_isPasswordResetServerIssue(action)) {
                openPasswordResetWizard({
                    name: "openServiceCenterHoursPage",
                    data: {
                        serviceNotAvailable:
                            "This service is temporarily unavailable. Please try again later",
                    },
                });
                return;
            }
            if (_isOTPInputExceededMEssage(action)) {
                openPasswordResetWizard({
                    name: "PasscodePage",
                    data: {
                        exceededOTPMessage:
                            "Your account is not available at this time. Please contact Covered California for help.",
                    },
                });
                return;
            }
            if (_isOTPRetry(action)) {
                openPasswordResetWizard({
                    name: "PasscodePage",
                    data: { OTPRetry: "Please check your OTP and try again." },
                });
                return;
            }
            if (_isOTPServiceUnavailable(action)) {
                openPasswordResetWizard({
                    name: "PasscodePage",
                    data: {
                        OTPServiceUnavailable:
                            "This service is temporarily unavailable. Please try again later",
                    }
                });
                return;
            }
            if (_isPasswordReset(action)) {
                openPasswordResetWizard({ name: "openServiceCenterPage", data: {} });
                return;
            }
            if (_isPasswordInvalid(action)) {
                openPasswordResetWizard({
                    name: "openPasswordPage",
                    data: {
                        passwordInvalid:
                            "We could not update your password. You may have entered a password that you've already used in the past, a password that contains a dictionary word, or a password that contains a keyboard pattern. Please enter a new password.",
                    },
                });
                return;
            }

            if (_isSuccessfullyChangedPasswordMessage(action)) {
                openPasswordResetWizard({ name: "openSuccessPage", data: {} });
            }
            return;
        }
        const attachment = action.payload.activity.attachments[0];
        if (attachment.contentType !== "application/vnd.microsoft.card.adaptive") {
            switch (attachment.contentType) {
                case "application/otpTypeCard":
                    openPasswordResetWizard({
                        name: "openOtpSelectionPage",
                        data: action.payload.activity,
                    });
                    break;
                case "application/contactUsCard":
                    // openPasswordResetWizard({name: "openContactUsPage", data: ""})
                    break;
                case "application/passcodeCard":
                    openPasswordResetWizard({
                        name: "openPasscodePage",
                        data: action.payload.activity,
                    });
                    break;
                case "application/passwordCard":
                    openPasswordResetWizard({ name: "openPasswordPage", data: "" });
                    break;
                default:
                    openPasswordResetWizard({
                        name: "startWizard",
                        data: action.payload.activity,
                    });
            }
        } else {
            const adaptiveCardContent = attachment.content;
            const textBlock = adaptiveCardContent.body.find(
                (bodyElement) => bodyElement.type === "TextBlock"
            );
            if (textBlock) {
                const bodyText = textBlock.text.trim();
                switch (bodyText) {
                    case "You session has expired. Are you ready to continue resetting your password?":
                        openPasswordResetWizard({
                            name: "openServiceCenterPage",
                            data: action.payload.activity,
                        });
                        break;
                    default:
                        openPasswordResetWizard({
                            name: "startWizard",
                            data: action.payload.activity,
                        });
                }
            }
        }
    }

    const _isUsernameDob = (action) => {
        if(!_hasAttachments(action)) return false;
        if(action.payload.activity.attachments[0].contentType !== "application/usernameDobCard") return false;
        return true;
    }
    const _isCancel = (action) => {
        if(!_hasAttachments(action)) return false;
        if(!_isAdaptiveCard(action.payload.activity.attachments[0])) return false;
        const attachment = action.payload.activity.attachments[0];
        const adaptiveCardContent = attachment.content;
        const textBlock = adaptiveCardContent.body.find(
            (bodyElement) => bodyElement.type === "TextBlock"
        );
        if(!textBlock) return false;
        const bodyText = textBlock.text.trim();
        if(!bodyText) return false;
        if(bodyText !== "Are you sure you want to cancel resetting your password?") return false;
        return true;
    }
    const _hasAttachments = (action) => {
        if(action.payload.activity.attachments && action.payload.activity.attachments.length > 0) return true;
        return false;
    }
    const _isAdaptiveCard = (attachment) => {
        if(attachment.contentType === "application/vnd.microsoft.card.adaptive") return true;
        return false;
    }
    const _isCancelConfirmed = (action) => {
        if (action.payload.activity.text === "No problem. What else can I help you with?"){
            return true;
        }
        return false;
    }
    const _isExceededAttemptMessage = (action) => {
        if (
            action.payload.activity.text ===
            "Passwords can only be changed once per day. If you need to change your password, wait until tomorrow or call (800) 300-1506 for more help."
        ) {
            return true;
        }
    };
    const _isUserNotFound = (action) => {
        if (
            action.payload.activity.text ===
            "We couldn't find an account with this information on file. Please try again."
        ) {
            return true;
        }
    };
    const _isSuccessfullyChangedPasswordMessage = (action) => {
        if (
            action.payload.activity.text ===
            "You have successfully changed you password. Please try to [login](https://apply.coveredca.com/static/lw-web/login) or continue to ask me questions"
        ) {
            return true;
        }
        return false;
    };
    const _isPasswordResetServerIssue = (action) => {
        if (
            action.payload.activity.text ===
            "This service is temporarily unavailable. Please try again later"
        ) {
            return true;
        }
        return false;
    };
    const _isOTPInputExceededMEssage = (action) => {
        if (
            action.payload.activity.text ===
            "Your account is not available at this time. Please contact Covered California for help."
        ) {
            return true;
        }
        return false;
    };
    const _isOTPRetry = (action) => {
        if (
            action.payload.activity.text === "Please check your OTP and try again."
        ) {
            return true;
        }
        return false;
    };
    const _isOTPServiceUnavailable = (action) => {
        if (
            action.payload.activity.text ===
            "This service is temporarily unavailable. Please try again later"
        ) {
            return true;
        }
        return false;
    };
    const _isPasswordReset = (action) => {
        const activity = action.payload.activity;
        if (activity.attachments && activity.attachments.length > 0) {
            return activity.attachments.some((attachment) => {
                return (
                    attachment.content &&
                    attachment.content.type === "AdaptiveCard" &&
                    attachment.content.body.some((bodyElement) => {
                        return (
                            bodyElement.type === "TextBlock" &&
                            bodyElement.text ===
                            "Are you sure you want to cancel resetting your password?\n\n\n\n "
                        );
                    })
                );
            });
        }

        return false;
    };
    const _isPasswordInvalid = (action) => {
        if (
            action.payload.activity.text ===
            "We could not update your password. You may have entered a password that you've already used in the past, a password that contains a dictionary word, or a password that contains a keyboard pattern. Please enter a new password."
        ) {
            return true;
        }
        return false;
    };

    return {
        handlePasswordResetAction
    }
}

export default usePasswordResetActionHandler;