import React, { useEffect } from 'react';
import ReactWebChat from 'botframework-webchat';
import useWebChat from '../../Hooks/useWebChat';

import ChatbotLayout from '../../UiKit/Chatbot/ChatbotLayout/ChatbotLayout';
import Header from '../../UiKit/Chatbot/Header/Header';
import Footer from '../../UiKit/Chatbot/Footer/Footer';
import WebchatMenu from './WebchatMenu/WebchatMenu';
import useChatbot from '../../Hooks/useChatbot';
import activityMiddleware from './ActivityMiddleware';
import WelcomeScreen from './WelcomeScreen/WelcomeScreen';
import attachmentMiddleware from './AttachmentMiddleware';
import useLanguageHook from '../../Hooks/useLanguageHook';

function Webchat() {
    const { directline, store, initializeWebchat, isInitialized, hasUserSentMessage, styleOptions, locale } = useWebChat();
    const { hasBeenOpened, showWelcomeScreen } = useChatbot();
    const { currentLanguage } = useLanguageHook();

    useEffect(() => {
        if (hasBeenOpened && !isInitialized()) {
            initializeWebchat();
        }
    }, [hasBeenOpened]);

    return (
        <ChatbotLayout>
            <ChatbotLayout.Header>
                <Header />
                <WebchatMenu />
            </ChatbotLayout.Header>

            <ChatbotLayout.Webchat>
                {directline && store ?
                    <>
                        {showWelcomeScreen ? <WelcomeScreen /> : null}
                        <ReactWebChat
                            directLine={directline}
                            store={store}
                            activityMiddleware={activityMiddleware}
                            attachmentMiddleware={attachmentMiddleware}
                            styleOptions={styleOptions}
                            locale={locale}
                        />
                    </>
                    : null
                }
            </ChatbotLayout.Webchat>

            <ChatbotLayout.Footer>
                <Footer />
            </ChatbotLayout.Footer>
        </ChatbotLayout>
    )
}

export default Webchat;