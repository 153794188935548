import React, { createContext, useState } from "react";
export const ChatbotContext = createContext();

const ChatbotContextProvider = ({ children }) => {
  const [theme, setTheme] = useState();
  const [isInApp, setIsInApp] = useState(false);
  const [language, setLanguage] = useState();

  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showWelcomeScreen, setShowWelcomScreen] = useState(true);
  const [closeModal, setCloseModal] = useState({isOpen: false, type: null});
  const [helpModal, setHelpModal] = useState({isOpen: false, type: null})
  const [offBusinessHoursHelpModal, setOffBusinessHoursHelpModal] = useState({isOpen: false, type: null})

  const [isAffordabilityToolWizardOpen, setIsAffordabilityToolWizardOpen] = useState(false);
  const [isEnrollmentStatusWizardOpen, setIsEnrollmentStatusWizardOpen] = useState(false);
  const [isM2CWizardOpen, setIsM2CWizardOpen] = useState(false);
  const [enrollmentStatusWizardEventData, setEnrollmentStatusWizardEventData] = useState();
  const [M2CWizardEventData, setM2CWizardEventData] = useState();
  const [isPasswordResetWizardOpen, setIsPasswordResetWizardOpen] = useState(false);
  const [sb260Event, setSb260Event] = useState({
    name: "",
    data: {},
    code: ""
  });
  const [enrollmentStatusEvent, setEnrollmentStatusEvent] = useState({
    name: "",
    data: {},
    code: ""
  });
  const [isPasswordResetMenuOpen, setIsPasswordResetMenuOpen] = useState(false);
  const [passwordResetEventData, setPasswordResetEventData] = useState();
  const [passwordResetEvent, setPasswordResetEvent] = useState({
    name: "",
    data: {}
  });


  return (
    <ChatbotContext.Provider
      value={{
        setIsMenuOpen,
        isMenuOpen,
        language,
        setLanguage,
        isAffordabilityToolWizardOpen,
        setIsAffordabilityToolWizardOpen,
        isEnrollmentStatusWizardOpen,
        setIsEnrollmentStatusWizardOpen,
        isM2CWizardOpen,
        setIsM2CWizardOpen,
        enrollmentStatusWizardEventData,
        setEnrollmentStatusWizardEventData,
        M2CWizardEventData,
        setM2CWizardEventData,
        isMinimized,
        setIsMinimized,
        isClosed,
        setIsClosed,
        hasBeenOpened,
        setHasBeenOpened,
        sb260Event,
        setSb260Event,
        setEnrollmentStatusEvent,
        enrollmentStatusEvent,
        showWelcomeScreen,
        setShowWelcomScreen,
        passwordResetEvent,
        setPasswordResetEvent,
        passwordResetEventData,
        setPasswordResetEventData,
        isPasswordResetWizardOpen,
        setIsPasswordResetWizardOpen,
        isPasswordResetMenuOpen,
        setIsPasswordResetMenuOpen,
        closeModal,
        setCloseModal,
        helpModal,
        setHelpModal,
        offBusinessHoursHelpModal,
        setOffBusinessHoursHelpModal,
              }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};

export default ChatbotContextProvider;
