import React, { useState, useEffect, useRef, useContext } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import usePasswordReset from "../../Hooks/usePasswordReset";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Form from "../../UiKit/Form/Form";
import Input from "../../UiKit/Wizard/Input/Input";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import Body from "../../UiKit/Wizard/Body/Body";
import { PasswordResetContext } from "../../Contexts/PasswordResetContext";
import useChatbot from "../../Hooks/useChatbot";
import Alert from "../../UiKit/Wizard/Alert/Alert";
import InputValidationMessage from "../../UiKit/InputValidationMessage/InputValidationMessage";
import DidntGetPasscodeModal from "./DidntGetPasscodeModal";

function PasscodePage(props) {
  const { next, setIsDidNotGetPasscodeModalOpen } = usePasswordReset();
  const {
    passcode,
    setPasscode,
    exceededOTPMessage,
    phone,
    email,
    otpType,
    maskedPhone,
    maskedEmail,
  } = useContext(PasswordResetContext);
  const { passwordResetEvent, closePasswordResetWizard } = useChatbot();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [useExpiredToken, setUseExpiredToken] = useState(false);
  const [isUsedExpiredTokenVisible, setIsUsedExpiredTokenVisible] =
    useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const inputRefs = useRef([]);

  const [otpRetryMessage, setOtpRetryMessage] = useState("");
  const [otpSessionExpired, setOtpSessionExpired] = useState("");
  const [OTPServiceUnavailable, setOTPServiceUnavailable] = useState("");
  const { postActivity, sendActivity, onPrevious } = props;
  const [showExitModal, setshowExitModal] = useState(false);

  const linkStyle = {
    color: "var(--CalHEERS-Main-Blue, #2D77A3)",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    textDecorationLine: "underline",
  };

  useEffect(() => {
    console.log("Received activity data: ", passwordResetEvent);
  }, [passwordResetEvent]);

  useEffect(() => {
    if (props.OTPRetry) {
      setOtpRetryMessage(props.OTPRetry);
    }
  }, [props.OTPRetry]);

  useEffect(() => {
    if (props.OTPSessionExpired) {
      setOtpSessionExpired(props.OTPSessionExpired);
    }
  }, [props.OTPSessionExpired]);

  useEffect(() => {
    if (props.OTPServiceUnavailable) {
      setOTPServiceUnavailable(props.OTPServiceUnavailable);
    }
  }, [props.OTPServiceUnavailable]);

  const isPasscodeValid = (passcode) => {
    const trimmedPasscode = passcode.trim();
    console.log("trimmedPasscode", trimmedPasscode);
    return trimmedPasscode.length === 6 && trimmedPasscode !== "000000";
  };
  console.log("otpType", otpType);
  const onSubmit = () => {
    // Trim any potential whitespace and check if the passcode is empty or consists of only zeros
    const trimmedPasscode = passcode.trim();

    if (!isPasscodeValid(trimmedPasscode)) {
      console.log("Please enter a valid 6-digit one-time passcode.");
      setShowValidationMessage(true);
      return;
    }
    console.log("otpType", otpType);

    sendActivity({
      type: "message",
      value: {
        type: "forgotPasswordCardClick",
        passcode: trimmedPasscode,
        intentName: "forgotPassword",
        useExpiredToken: useExpiredToken,
        contactMethod: otpType === "phone" ? phone : email,
      },
    });
    setIsDisabled(true);
  };

  const onPasscodeChange = (index, newValue) => {
    setShowValidationMessage(false);
    const newPasscode = passcode.split("");
    newPasscode[index] = newValue;
    const updatedPasscode = newPasscode.join("");
    setPasscode(updatedPasscode);

    if (isPasscodeValid(updatedPasscode)) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }

    // Move to next input if value is not empty and it's not the last input
    if (newValue && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  //let userPreferredOtpType = "email";

  //console.log("maskedPhone", maskedPhone)
  // let userPreferredOtpType = otpType === "phone" ? maskedPhone : maskedEmail;

  // if (passwordResetEvent && passwordResetEvent.data.attachments && passwordResetEvent.data.attachments.length > 0 && passwordResetEvent.data.attachments[0] && passwordResetEvent.data.attachments[0].content && passwordResetEvent.data.attachments[0].content.data) {
  //     userPreferredOtpType = passwordResetEvent.data.attachments[0].content.data.userPreferredOtpType === "phone" ? "text message" : "email";
  // }

  let userPreferredOtpType = "email"; // Default to email

  if (otpType) {
    userPreferredOtpType = otpType === "phone" ? maskedPhone : maskedEmail;
  } else if (
    passwordResetEvent &&
    passwordResetEvent.data.attachments &&
    passwordResetEvent.data.attachments.length > 0 &&
    passwordResetEvent.data.attachments[0].content &&
    passwordResetEvent.data.attachments[0].content.data
  ) {
    userPreferredOtpType =
      passwordResetEvent.data.attachments[0].content.data
        .userPreferredOtpType === "phone"
        ? "text message"
        : "email";
  }

  console.log("Phone:", phone);
  console.log("Email:", email);

  useEffect(() => {
    localStorage.setItem("conversationMayContainPii", true);
  }, []);

  const renderPasscodeInputs = () => {
    return Array.from({ length: 6 }).map((_, index) => (
      <input
        key={index}
        type="text"
        maxLength="1"
        value={passcode[index] || ""}
        onChange={(e) => onPasscodeChange(index, e.target.value)}
        ref={(el) => (inputRefs.current[index] = el)}
        disabled={isDisabled}
        style={{
          display: "flex",
          width: "34px",
          padding: "10px 12px",
          alignItems: "center",
          gap: "10px",
          borderRadius: "3px",
          border: `1px solid ${
            showValidationMessage
              ? "red"
              : "var(--Grayscale-Gray---200, #BABABC)"
          }`,
          background: "var(--Background-Default, #FFF)",
          textAlign: "center",
          marginRight: "10px",
        }}
      />
    ));
  };

  return (
    <PageLayout>
      <PageLayout.Header style={{ marginBottom: "8px" }}>
        <SubHeader subHeader="Verify your Identity" />
      </PageLayout.Header>
      <PageLayout.Content>
        <Body
          bodyText={
            <>
              <p>
                We sent a one-time passcode to {userPreferredOtpType}.
                {/* We sent a one-time passcode to {userPreferredOtpType === "phone" ? phone : email}. */}
                {/* We sent a one-time passcode to {otpType === "phone" ? maskedPhone : maskedEmail}. */}
              </p>
            </>
          }
        />
        <Form>
          <Form.FormGroup>
            <Form.Label>Enter One-time Passcode</Form.Label>
            <Form.Control style={{ display: "flex" }}>
              {renderPasscodeInputs()}
            </Form.Control>
            {isUsedExpiredTokenVisible && (
              <div>
                <input
                  id="use-expired-token"
                  type="checkbox"
                  checked={useExpiredToken}
                  onChange={() => setUseExpiredToken(!useExpiredToken)}
                />
                <label htmlFor="use-expired-token">Use expired token</label>
              </div>
            )}
            {showValidationMessage && (
              <InputValidationMessage>
                One-time passcode is invalid.
              </InputValidationMessage>
            )}
          </Form.FormGroup>
        </Form>
        <a href="#!" style={linkStyle} onClick={() => setIsDidNotGetPasscodeModalOpen(true)}>
          Didn’t get your passcode?
        </a>

        {props.exceededOTPMessage && (
          <Alert
            iconType="red"
            frameIcon="red"
            message={props.exceededOTPMessage}
          />
        )}
        {otpRetryMessage && (
          <Alert iconType="red" frameIcon="red" message={otpRetryMessage} />
        )}
        {otpSessionExpired && (
          <Alert iconType="red" frameIcon="red" message={otpSessionExpired} />
        )}
        {OTPServiceUnavailable && (
          <Alert
            iconType="red"
            frameIcon="red"
            message={OTPServiceUnavailable}
          />
        )}
      </PageLayout.Content>
      <WizardLayout.Navigation>
        <NavigationButton
          onClick={onSubmit}
          type="primary"
          disabled={isSubmitDisabled || isDisabled}
        >
          Submit
        </NavigationButton>
        {/* <NavigationButton
          onClick={() => closePasswordResetWizard()}
          type={"secondary"}
        >
          Exit
        </NavigationButton> */}
      </WizardLayout.Navigation>
    </PageLayout>
  );
}

export default PasscodePage;
