import { useEffect } from 'react';
import useChatbotContext from "./useChatbotContext";

function useChatbot() {
  const {
    setIsMenuOpen,
    isMenuOpen,
    language,
    setLanguage,
    isAffordabilityToolWizardOpen,
    setIsAffordabilityToolWizardOpen,
    isEnrollmentStatusWizardOpen,
    setIsEnrollmentStatusWizardOpen,
    isM2CWizardOpen,
    setIsM2CWizardOpen,
    enrollmentStatusWizardEventData,
    setEnrollmentStatusWizardEventData,
    M2CWizardEventData,
    setM2CWizardEventData,
    isMinimized,
    setIsMinimized,
    isClosed,
    setIsClosed,
    sb260Event,
    setSb260Event,
    hasBeenOpened,
    setHasBeenOpened,
    setEnrollmentStatusEvent,
    enrollmentStatusEvent,
    showWelcomeScreen,
    setShowWelcomScreen,
    setPasswordResetEvent,
    passwordResetEvent,
    passwordResetEventData,
    setPasswordResetEventData,
    isPasswordResetMenuOpen,
    setIsPasswordResetMenuOpen,
  } = useChatbotContext();

  useEffect(() => {
  }, [sb260Event]);

  useEffect(() => {
  }, [enrollmentStatusEvent]);


  const closeAllWizards = () => {
    closeAffordabilityTool();
    closeEnrollmentStatusWizard();
  };

  const openAffordabilityTool = () => {
    closeAllWizards();
    setIsAffordabilityToolWizardOpen(true);
  };
  const closeAffordabilityTool = () => {
    setIsAffordabilityToolWizardOpen(false);
  };
  const openEnrollmentStatusWizard = (data) => {
    closeAllWizards();
    setIsEnrollmentStatusWizardOpen(true);
    setEnrollmentStatusWizardEventData(data);
    setEnrollmentStatusEvent({
      name: data.name,
      data: data.data
    });
  };
  const closeEnrollmentStatusWizard = () => {
    setIsEnrollmentStatusWizardOpen(false);
    setEnrollmentStatusWizardEventData();
  };
  const openM2CWizard = (data) => {
    closeAllWizards();
    setIsM2CWizardOpen(true);
    setM2CWizardEventData(data);
    setSb260Event({
      name: data.name,
      data: data.data
    });
  };
  const closeM2CWizard = () => {
    setIsM2CWizardOpen(false);
    setM2CWizardEventData();
  };
  const openPasswordResetWizard = (PasswordResetEvent) => {
    closeAllWizards();
    setIsPasswordResetMenuOpen(true);
    setPasswordResetEvent(PasswordResetEvent);
    setPasswordResetEventData(PasswordResetEvent.data);
  };
  const closePasswordResetWizard = () => {
    setIsPasswordResetMenuOpen(false);
    closeAllWizards();
    setPasswordResetEvent({ name: '', data: {} });
    setPasswordResetEventData(null);
    
  };
  const openWelcomePage = () => {
    setPasswordResetEvent({ name: 'openWelcomePage', data: {} });
    setPasswordResetEventData(null);
    // setUsername("");
    // setDob("");
  };
  const minimize = () => {
    setIsMinimized(true);
  };
  const open = () => {
    if (!hasBeenOpened) setHasBeenOpened(true);
    setIsClosed(false);
    setIsMinimized(false);
  };
  const close = () => {
    setHasBeenOpened(false);
    setIsClosed(true);
    setIsMinimized(true);
    setIsMenuOpen(false);
    closeAllWizards();
  }
  const openChatbotMenu = () => {
    setIsMenuOpen(true);
  };
  const closeChatbotMenu = () => {
    setIsMenuOpen(false);
  };
  const toggleChatbotMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };




  return {
    isMinimized,
    isAffordabilityToolWizardOpen,
    isEnrollmentStatusWizardOpen,
    enrollmentStatusWizardEventData,
    isM2CWizardOpen,
    M2CWizardEventData,
    hasBeenOpened,
    isClosed,
    close,
    openM2CWizard,
    closeM2CWizard,
    open,
    minimize,
    openChatbotMenu,
    closeChatbotMenu,
    toggleChatbotMenu,
    closeAffordabilityTool,
    openAffordabilityTool,
    openEnrollmentStatusWizard,
    closeEnrollmentStatusWizard,
    sb260Event,
    showWelcomeScreen,
    setShowWelcomScreen,
    openPasswordResetWizard,
    closePasswordResetWizard,
    passwordResetEvent,
    openWelcomePage,
    isPasswordResetMenuOpen
  };
}

export default useChatbot;
