import React, { useContext } from 'react';
import { useState } from 'react';
import ChatMenu from "../../../UiKit/Chatbot/ChatMenu/ChatMenu";
import { ChatbotContext } from '../../../Contexts/ChatbotContext';
import { Accordion, Card } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import acccordianItemCloseIcon from "../../../Images/chat-menu-accordion-toggle-close.svg";
import acccordianItemOpenIcon from "../../../Images/chat-menu-accordion-toggle-open.svg";
import Pill from "../../../UiKit/Pill/Pill";
import PillIconPhoneSupport from '../../../Images/pill-icon-phone-support.svg';

import PillIconLiveChatActive from '../../../Images/live-chat-icon-white.svg'
import PillIconPhoneSupportActive from '../../../Images/pill-icon-phone-support--white.svg'

import LanguageSelectorCheckmark from "../../../Images/language-selector-check.svg";
import ChatMenuAccordionClasses from "../../../UiKit/Chatbot/ChatMenu/ChatMenuAccordion.module.css";
import { useLanguage } from '../../../UiKit/Wizard/langugeContext';
import useServiceHour from '../../../Hooks/useServiceHours';
import serviceHours from '../../../Utilities/ServiceHours'
import translations from './translation';
import NavigationButton from '../../../UiKit/Wizard/NavigationButton/NavigationButton';
import useWebChat from '../../../Hooks/useWebchat/useWebChat';
import PillIconLiveChatNew from '../../../Images/pill-icon-live-chat.svg';
import PillIconFindEnroller from '../../../Images/pill-icon-location-pin.svg';
import PillIconHelpInYourLanguage from '../../../Images/pill-icon-globe.svg';

function WebchatMenu() {
    const { isMenuOpen, setIsMenuOpen, openRelativeHelpModal, openRelativeOffHoursHelpModal, helpModal, offBusinessHoursHelpModal, } = useContext(ChatbotContext);
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [selectedPill, setSelectedPill] = useState(null);
    const { currentLanguage } = useLanguage();
    const webChatMenuTranslations = translations[currentLanguage]["ChatMenu"];
    const { isServiceHour, serviceHourData } = useServiceHour();
    const { sendMessage } = useWebChat();

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
    }
    const handlePillSelect = (pill) => {
        console.log("pill select", pill)
        if(pill === "Live Chat"){
            setIsMenuOpen(false);
            sendMessage("Live Chat");
        }
        if(pill === "Call Us"){
            setIsMenuOpen(false);
            sendMessage("Call a representative");
        }
        if(pill === "Find an Enroller"){
            setIsMenuOpen(false);
            sendMessage("Find an Enroller");
        }
        if(pill === "Help in your Language"){
            setIsMenuOpen(false);
            sendMessage("Help in your language");
        }
        setSelectedPill(pill);
    }

    const isWithinServiceHours = (serviceHourData, selectedLanguage) =>{
            return serviceHours(serviceHourData, selectedLanguage)
    }

    return (
        <ChatMenu isOpen={isMenuOpen}>
            <ChatMenu.Header>
                <ChatMenu.HeaderBackButtonContainer>
                    {/* <ChatMenu.HeaderBackButton onClose={() => setIsMenuOpen(false)}>
                        <img src={backButtonIcon} alt="Back Button" />
                    </ChatMenu.HeaderBackButton> */}
                </ChatMenu.HeaderBackButtonContainer>
                <ChatMenu.HeaderText>
                    {webChatMenuTranslations.ToolMenuTitle}
                </ChatMenu.HeaderText>
            </ChatMenu.Header>

            <ChatMenu.Body>
            <Accordion className={ChatMenuAccordionClasses['chat-menu-accordion']}>
                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']}>
                            <CustomToggle eventKey="0">{webChatMenuTranslations.ContactSupport.Title}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                        <div style={{ position:"static", pospaddingTop: "16px", paddingBottom: "16px" }}>
                                <Pill
                                    variant={'outline-primary'}
                                    onClick={() => { console.log("clicked pill")
                                        handlePillSelect('Live Chat')}}
                                >
                                    <Pill.Icon initialIcon={PillIconLiveChatNew} activeIcon={PillIconLiveChatActive} />
                                    <Pill.Text>{webChatMenuTranslations.ContactSupport.liveChat}</Pill.Text>
                                </Pill>
                                <Pill
                                    variant={selectedPill === 'Call Us' ? 'primary' : 'outline-primary'}
                                    onClick={() => handlePillSelect('Call Us')}
                                >
                                    <Pill.Icon initialIcon={PillIconPhoneSupport} activeIcon={PillIconPhoneSupportActive} />
                                    <Pill.Text>{webChatMenuTranslations.ContactSupport.callUs}</Pill.Text>
                                </Pill>
                                <Pill
                                    variant={selectedPill === 'Find an Enroller' ? 'primary' : 'outline-primary'}
                                    onClick={() => handlePillSelect('Find an Enroller')}
                                >
                                    <Pill.Icon initialIcon={PillIconFindEnroller} activeIcon={PillIconPhoneSupportActive} />
                                    <Pill.Text>{webChatMenuTranslations.ContactSupport.findAnEnroller}</Pill.Text>
                                </Pill>
                                {currentLanguage === 'en' && (
                                        <Pill
                                            variant={selectedPill === 'Help in your Language' ? 'primary' : 'outline-primary'}
                                            onClick={() => handlePillSelect('Help in your Language')}
                                        >
                                            <Pill.Icon initialIcon={PillIconHelpInYourLanguage} activeIcon={PillIconPhoneSupportActive} />
                                            <Pill.Text>{webChatMenuTranslations.ContactSupport.helpInYourLanguage}</Pill.Text>
                                        </Pill>
                                    )}              
                            </div>
                        </Accordion.Collapse>
                    </Card>

                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']}  style={{border:"none", background:"white"}}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']} style={{border:"none", background:"white"}}>
                            <CustomToggle eventKey="2">{webChatMenuTranslations.language.language}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                            <div style={{padding: '12px'}} className={'English'} onClick={() => handleLanguageSelect('English') }>
                                <span>{webChatMenuTranslations.language.english}</span>
                                {selectedLanguage === 'English' && 
                                    <img src={LanguageSelectorCheckmark} 
                                    alt="Selected" 
                                    style={{ marginLeft: '8px', width: '16px', height: '16px' }} />}
                            </div>
                            <div style={{padding: '12px'}} className={'Spanish'} onClick={() => handleLanguageSelect('Spanish')}>
                                <span>{webChatMenuTranslations.language.spanish}</span>
                                {selectedLanguage === 'Spanish' && 
                                    <img src={LanguageSelectorCheckmark} 
                                    alt="Selected"
                                    style={{ marginLeft: '8px', width: '16px', height: '16px' }} />}
                            </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']}  style={{border:"none", background:"white"}}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']} style={{borderBottom:"1px #D1D1D1", background:"white"}}>
                            <CustomToggle eventKey="3">{webChatMenuTranslations.privacy.privacy}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <div style={{ padding: "16px 12px" }}>
                                <p>
                                   {webChatMenuTranslations.privacy.privacyPolicy.privacyPolicyFirst}
                                   {webChatMenuTranslations.privacy.privacyPolicy.privacyPolicyLink} <a href="https://www.coveredca.com/privacy" target="_blank" rel="noopener noreferrer" style={{color: "#2D77A3"}}> {webChatMenuTranslations.privacy.privacyPolicy.privacyPolicySecond}</a>
                                </p>
                            </div>
                        </Accordion.Collapse>
                    </Card>

                    <Card className={ChatMenuAccordionClasses['chat-menu-accordion__item']} style={{border:"none", background:"white"}}>
                        <Card.Header className={ChatMenuAccordionClasses['chat-menu-accordion__header']} style={{border:"none", background:"white"}}>
                            <CustomToggle eventKey="4">{webChatMenuTranslations.Feedback.Feedback}</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <div style={{ padding: "16px 12px" }}>
                                <p>{webChatMenuTranslations.Feedback.feedbackText.first}
                                    <a 
                                        href="#"  // Removed /privacy link if you only want to execute sendMessage
                                        style={{color: "#2D77A3", marginLeft: "4px"}}
                                        onClick={(e) => {
                                            e.preventDefault(); 
                                            setIsMenuOpen(false) // Prevent default anchor behavior
                                            sendMessage("Provide Feedback");  // Call the sendMessage() function
                                        }}
                                    >
                                         {webChatMenuTranslations.Feedback.feedbackText.second}.
                                    </a>
                                </p>
                            </div>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </ChatMenu.Body>

            <ChatMenu.Footer>
                <div style={{ padding: "16px", backgroundColor: "white" }}>
                    <NavigationButton type="primary" onClick={() => setIsMenuOpen(false)}>
                        {webChatMenuTranslations.ToolMenuFooter.label}
                    </NavigationButton>
                </div>
            </ChatMenu.Footer>
        </ChatMenu>
    )
}

function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
            className={`${ChatMenuAccordionClasses['chat-menu-accordion__toggle']} ${isCurrentEventKey ? ChatMenuAccordionClasses['chat-menu-accordion__toggle--active'] : ""}`}
            type="button"
            onClick={decoratedOnClick}
            style={{border:"none", background:"white"}}
        >
            <div>
                {children}
            </div>
            {isCurrentEventKey ?
                <img style={{ marginLeft: "auto" }} src={acccordianItemCloseIcon} alt="Close Icon" />
                :
                <img style={{ marginLeft: "auto" }} src={acccordianItemOpenIcon} alt="Open Icon" />
            }
        </button>
    );
}

export default WebchatMenu;