import React from 'react';
const Es = "es";
const En = "en";

export const translations = {
    en: {
        'welcomeScreen': {
        'heading': "I’m CiCi, your virtual assistant.",
        'subHeading': "Here to help! Choose a topic or ask a question."
        },

        'welcomeScreenSuggestedActions': {
            'suggestedActions': [
                {
                    'title': "Get coverage",
                },
                {
                    'title': "My account",
                },
                {
                    'title': "Login help",
                },
                {
                    'title': "My Medi-Cal is ending",
                },
                
                
            ]
        }
    },
    es: {
        'welcomeScreen': {
            'heading': "Soy CiCi, tu asistente virtual.",
            'subHeading': "¡Estoy aquí para ayudar! Elige un tema o haz una pregunta."
        },

        'welcomeScreenSuggestedActions': {
            'suggestedActions': [
                {
                    'title': "Obténer cobertura",
                },
                {
                    'title': "Mi cuenta",
                },
                {
                    'title': "Ayuda para iniciar sesión",
                },
                {
                    'title': "Mi Medi-Cal está por terminar",
                },
                
            ]
        }
    }
};

export default translations;