const usePostActionHandler = () => {

    const handlePostAction = (action, next) => {
        return next(_updatePostAction(action))
    };

    const _updatePostAction = (action) => {
        const postActionWithGaId = _addGaId(action);
        const postActionWithPageUrl = _addPageUrl(postActionWithGaId);
        return postActionWithPageUrl;
    };

    const _addGaId = (action) => {
        action = { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, gaId: getGaId() } } }
        return action;
    };
    
    const _addPageUrl = (action) => {
        action = { ...action, payload: { ...action.payload, activity: { ...action.payload.activity, pageUrl: window.location.pathname } } }
        return action;
    };

    function getGaId(){
        const cookieString = document.cookie;
        const cookieArrayDirty = cookieString.split(";");
        const cookieArrayClean = getCleanCookieArray(cookieArrayDirty);
        return findGaCookie(cookieArrayClean);
    }
    
    function findGaCookie(cookieArrayClean){
        const gaCookie = cookieArrayClean.find(cookieItem => cookieItem.key === "_ga");
        if(gaCookie){
            return gaCookie.value;
        }
        return "";
    }
    
    function getCleanCookieArray(cookieArrayDirty){
        const cookieArrayClean = [];
        for(let i=0; i<cookieArrayDirty.length; i++){
            const trimmed = cookieArrayDirty[i].trim();
            const keyPair = trimmed.split("=");
            const keyPairObject = {
                key: keyPair[0],
                value: keyPair[1]
            }
            cookieArrayClean.push(keyPairObject);
        }
        return cookieArrayClean;
    }

    return {
        handlePostAction
    }
}

export default usePostActionHandler;