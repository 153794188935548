import React, { useEffect , useState} from "react";
import classes from './Cici.module.css'; 
import ChatbotContextProvider from "../../Contexts/ChatbotContext";
import { LanguageProvider } from "../../UiKit/Wizard/langugeContext";
import Webchat from "../Webchat/Webchat";
import AffordabilityToolWizard from '../AffordabilityToolWizard/AffordabilityToolWizard';
import useWebChat from "../../Hooks/useWebChat";
import WebChatContextProvider from "../../Contexts/WebChatContext";
import useChatbot from "../../Hooks/useChatbot";
import DeveloperTools from "../DevelopmentHelpers/DeveloperTools";
import M2CWizard from "../M2CWizard/M2CWizard";
import PasswordResetWizard from "../PasswordResetWizard/PasswordResetWizard";
import EnrollmentStatusWizard from "../EnrollmentStatusWizard/EnrollmentStatusWizard";
import useLanguageHook from "../../Hooks/useLanguageHook";
// import chatbotincon from '../../Images/chatbot_bot_icon.svg';
import closeIcon from '../../Images/close.svg';
//import headerCloseicon from '../../Images/header-close-icon.svg';
import chatbotHoverIcon from '../../Images/chatbot_bot_hover_icon.svg';
import chatBotIcon from "../../Images/CiCi-Desktop-Default 2.svg";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function Cici() {
    return (
        <LanguageProvider>
            <ChatbotContextProvider>
                <WebChatContextProvider>
                    <CiciHelper />
                </WebChatContextProvider>
            </ChatbotContextProvider>
        </LanguageProvider>
    );
}
 
function CiciHelper() {
    const { open, close, isMinimized, isClosed, isAffordabilityToolWizardOpen, isM2CWizardOpen, sb260Event, isEnrollmentStatusWizardOpen, isPasswordResetMenuOpen, enrollmentStatusEvent, passwordResetEvent } = useChatbot();
    const { sendActivity, sendLoggingEventToBackend, sendMessage, postActivity } = useWebChat();
    const { updateLanguage } = useLanguageHook();
    const { currentLanguage } = useLanguage();

    const [isTextBoxVisible, setTextBoxVisible] = useState(true); // Keep track of help box visibility
    const [isCiciVisible, setCiciVisible] = useState(true); // Assume Cici is visible from the start
    const [isHovered, setIsHovered] = useState(false); // Track hover state for the chatbot icon

    // Handle page language and call the hook at the top level
    useEffect(() => {
        const isPageSpanish = () => {
            return document.location.href.indexOf('espanol') !== -1;
        };

        if (isPageSpanish()) {
            updateLanguage('es');
        } else {
            updateLanguage('en');
        }
    }, [updateLanguage]);

    useEffect(() => {
        setCiciVisible(true); // Cici should always be visible
    }, []);

    if (isClosed || !isCiciVisible) return null;

    const shouldHideWebchat = () => {
        return isAffordabilityToolWizardOpen || isM2CWizardOpen || isEnrollmentStatusWizardOpen || isPasswordResetMenuOpen;
    };

    const getCiciClasses = () => {
        let ciciClasses = classes['cici'];
        if (isMinimized) ciciClasses += ` ${classes['cici--is-minimized']}`;
        return ciciClasses;
    };

    return (
        <div className={getCiciClasses()}>
            {process.env.NODE_ENV === "development" ? <DeveloperTools /> : null}

            {isMinimized && (
                <div className={classes['cici__wrapper']}>
                    {isTextBoxVisible && (
                        <div className={classes['cici__text-box']}
                            //  style={{
                            //      marginLeft: isHovered ? '57px' : '36px', // Change position on hover
                            //      top: isHovered ? '-45px' : '-36px' // Adjust top on hover
                            //  }}
                             >
                            <p className={classes['cici__question-text']}>
                                {currentLanguage === 'es' ? '¿Preguntas?' : 'Questions?'}
                            </p>
                            <p className={classes['cici__help-text']}>
                                {currentLanguage === 'es' ? 'Estoy aquí para ayudar.' : "I'm here to help."}
                            </p>
                            <button
                                className={classes['cici__close-button']}
                                onClick={() => setTextBoxVisible(false)} 
                            >
                                <img src={closeIcon} alt="Close Icon" />
                            </button>
                        </div>
                    )}
                    <button
                        className={classes['cici__open-button']}
                        onClick={open} 
                        onMouseEnter={() => setIsHovered(true)} 
                        onMouseLeave={() => setIsHovered(false)} 
                    >
                        <img src={chatBotIcon} alt="Chatbot Icon" />
                    </button>
                </div>
            )}

            {!isMinimized && (
                <div className={`${classes['cici__body']}`}>
                    <div className={`${shouldHideWebchat() ? 'd-none' : ''}`}>
                        <Webchat />
                    </div>
                    {isAffordabilityToolWizardOpen && <AffordabilityToolWizard sendLoggingEventToBackend={sendLoggingEventToBackend} />}
                    {isM2CWizardOpen && <M2CWizard sendActivity={sendActivity} event={sb260Event} sendLoggingEventToBackend={sendLoggingEventToBackend} />}
                    {isEnrollmentStatusWizardOpen && <EnrollmentStatusWizard sendActivity={sendActivity} event={enrollmentStatusEvent} sendLoggingEventToBackend={sendLoggingEventToBackend} sendMessage={sendMessage} />}
                    {isPasswordResetMenuOpen && <PasswordResetWizard postActivity={postActivity} sendActivity={sendActivity} event={passwordResetEvent} />}
                </div>
            )}
        </div>
    );
}
export default Cici;