
import React from 'react';

const Es = "es";
const En = "en";

export const translation = {
    en: {
        ChatMenu: {
                ToolMenuTitle: "Menu",
                ContactSupport: {
                  Title: "Contact Support",
                  liveChat: "Live Chat",
                  callUs: "Call Us",
                  findAnEnroller: "Find an Enroller",
                  helpInYourLanguage: "Help in your language",
                },
                language: {
                  language: "Language",
                  english: "English",
                  spanish: "Spanish"
                },
                privacy: {
                  privacy: "Privacy",
                  privacyPolicy: {
                    privacyPolicyFirst: "Do not share Personally Identifiable Information, like your Social Security Number, date of birth, case number, or last name.",
                    privacyPolicyLink: "View our",
                    privacyPolicySecond: "Privacy Policy",
                  },
                },
                Feedback: {
                  Feedback: "Feedback",
                  feedbackText:{
                    first:"We'd love to know how we are doing. Let us know by",
                    second: "providing feedback",
                  }
                },
                ToolMenuFooter: {
                    label: "Close Menu"
                  }
            }
        },
    es: {
        ChatMenu: {
            ToolMenuTitle: "Menú",
                ContactSupport: {
                  Title: "Ayuda en vivo",
                  liveChat: "Chat en vivo",
                  callUs: "Ayuda en persona",
                  findAnEnroller: "Apoyo por teléfono",
                  helpInYourLanguage: "Help in your language",
                },
                language: {
                  language: "Idioma",
                  english: "English",
                  spanish: "Español"
                },
                privacy: {
                  privacy: "Privacidad",
                  privacyPolicy: {
                    privacyPolicyFirst: "No compartas información de identificación personal, como tu número de Seguro Social, fecha de nacimiento, número de caso o apellido.",
                    privacyPolicyLink: "Leé nuestra",
                    privacyPolicySecond: "Política de privacidad",
                  },
                },
                Feedback: {
                  Feedback: "Comentarios",
                  feedbackText:{
                    first:"Nos encantaría saber cómo fue tu experiencia. Déjanos tus",
                    second: " comentarios",
                  }
                },
                ToolMenuFooter: {
                    label: "Continuar"
                  }

    }
}
}

export default translation;
 
